/* .nb-theme-default .nb-spinner-container{position:relative}
.nb-theme-default nb-spinner{background-color:rgba(255,255,255,0.83)}
.nb-theme-default nb-spinner.active-spinner .spin-circle{border-right-color:#a4abb3}
.nb-theme-default nb-spinner.disabled-spinner .spin-circle{border-right-color:rgba(255,255,255,0.4)}
.nb-theme-default nb-spinner.primary-spinner .spin-circle{border-right-color:#8a7fff}
.nb-theme-default nb-spinner.info-spinner .spin-circle{border-right-color:#4ca6ff}
.nb-theme-default nb-spinner.success-spinner .spin-circle{border-right-color:#40dc7e}
.nb-theme-default nb-spinner.warning-spinner .spin-circle{border-right-color:#ffa100}
.nb-theme-default nb-spinner.danger-spinner .spin-circle{border-right-color:#ff4c6a}
.nb-theme-default nb-spinner .spin-circle{border-left-color:#e9edf2;border-top-color:#e9edf2;border-bottom-color:#e9edf2}
.nb-theme-default nb-spinner .message{color:#4b4b4b}
.nb-theme-default nb-spinner.xxsmall-spinner{font-size:1.25rem}
.nb-theme-default nb-spinner.xsmall-spinner{font-size:1.5rem}
.nb-theme-default nb-spinner.small-spinner{font-size:1.75rem}
.nb-theme-default nb-spinner.medium-spinner{font-size:2rem}
.nb-theme-default nb-spinner.large-spinner{font-size:2.25rem}
.nb-theme-default nb-spinner.xlarge-spinner{font-size:2.5rem}
.nb-theme-default nb-spinner.xxlarge-spinner{font-size:3rem} */
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(230deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.nb-spinner-container {
  position: relative;
}
.nb-spinner {
  opacity: 1;
  position: absolute;
  border-radius: inherit;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
}
.nb-spinner .spin-circle {
  animation: spin 0.8s infinite linear;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.125em;
  width: 1em;
  height: 1em;
}
.nb-spinner .message {
  margin-left: 0.5rem;
  line-height: 1rem;
  font-size: 1rem;
}

.nb-spinner {
  background-color: rgba(255, 255, 255, 0.63);
}
.nb-spinner.active-spinner .spin-circle {
  border-right-color: #a4abb3;
}
.nb-spinner.disabled-spinner .spin-circle {
  border-right-color: rgba(255, 255, 255, 0.4);
}
.nb-spinner.primary-spinner .spin-circle {
  border-right-color: #8a7fff;
}
.nb-spinner.info-spinner .spin-circle {
  border-right-color: #4ca6ff;
}
.nb-spinner.success-spinner .spin-circle {
  border-right-color: #40dc7e;
}
.nb-spinner.warning-spinner .spin-circle {
  border-right-color: #ffa100;
}
.nb-spinner.danger-spinner .spin-circle {
  border-right-color: #ff4c6a;
}
.nb-spinner .spin-circle {
  border-left-color: #e9edf2;
  border-top-color: #e9edf2;
  border-bottom-color: #e9edf2;
}
.nb-spinner .message {
  color: #4b4b4b;
}
.nb-spinner.xxsmall-spinner {
  font-size: 1.25rem;
}
.nb-spinner.xsmall-spinner {
  font-size: 1.5rem;
}
.nb-spinner.small-spinner {
  font-size: 1.75rem;
}
.nb-spinner.medium-spinner {
  font-size: 2rem;
}
.nb-spinner.large-spinner {
  font-size: 2.25rem;
}
.nb-spinner.xlarge-spinner {
  font-size: 2.5rem;
}
.nb-spinner.xxlarge-spinner {
  font-size: 3rem;
}
